<template>
  <div class="add-post">
    <h1>Dodaj wpisy</h1>
    <el-form ref="addPost" :model="addPost" label-width="100px" class="add-post-form">
      <el-form-item
        label="Tytuł"
        prop="title"
        :rules="[
          { required: true, message: 'Tytuł jest wymagany'},
        ]"
      >
        <el-input v-model="addPost.title" type="title" autocomplete="off" />
      </el-form-item>
      <el-form-item
        label="Zdjęcie"
        prop="image"
        :rules="[
          { required: true, message: 'Zdjęcie jest wymagane'},
        ]"
      >
        <el-upload
          class="upload"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :on-change="handleChangeImages"
          :auto-upload="false"
          :limit="1"
          :file-list="fileList"
        >
          <el-button size="small" type="primary">Dodaj zdjęcie</el-button>
          <div slot="tip" class="el-upload__tip">jpg/png zdjęcia nie większe niż 500kb</div>
        </el-upload>
        <el-dialog :visible.sync="previewDialogVisible">
          <img width="100%" :src="previewDialogUrl" alt="">
        </el-dialog>
      </el-form-item>
      <el-form-item
        label="Treść"
        prop="content"
        :rules="[
          { required: true, message: 'Treść jest wymagana'},
        ]"
      >
      <vue-editor v-model="addPost.content" use-custom-image-handler @image-added="handleImageAdded" :editorOptions="editorSettings"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm()">Zapisz</el-button>
      </el-form-item>
    </el-form>
    <AddHtmlDialog
      v-model="htmlContent"
      :dialog-visible="insertDialog"
      @dialogContentChanged="updateValHtml"
      @close="insertDialog = false"
    />
  </div>
</template>

<script>
  
import { VueEditor,Quill } from 'vue2-editor';

import ImageResize from 'quill-image-resize-vue';
import { ImageDrop } from 'quill-image-drop-module';

import PostResource from '@/api/post';
const postResource = new PostResource();

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
import AddHtmlDialog from '@/views/newsletter/AddHtmlDialog';

var icons = Quill.import('ui/icons');
  icons['undo'] = '<svg width="800px" height="800px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><rect x="0" fill="none" width="20" height="20"/><g><path d="M4 16v-2H2v2H1v-5h1v2h2v-2h1v5H4zM7 16v-4H5.6v-1h3.7v1H8v4H7zM10 16v-5h1l1.4 3.4h.1L14 11h1v5h-1v-3.1h-.1l-1.1 2.5h-.6l-1.1-2.5H11V16h-1zM19 16h-3v-5h1v4h2v1zM9.4 4.2L7.1 6.5l2.3 2.3-.6 1.2-3.5-3.5L8.8 3l.6 1.2zm1.2 4.6l2.3-2.3-2.3-2.3.6-1.2 3.5 3.5-3.5 3.5-.6-1.2z"/></g></svg>';

export default {
  name: 'AddPost',
  components: {
    VueEditor,
    AddHtmlDialog
  },
  data() {
    return {
      local_value: '',
      htmlContent: '',
      insertDialog: false,
      addPost: {},
      fileList: [],
      previewDialogVisible: false,
      previewDialogUrl: '',
      editorSettings: {
            modules: {
                imageDrop: true,
                imageResize: {},
                toolbar:{
                    container: [
                        ["bold", "italic", "underline", "strike"],
                        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                        [
                          { align: "" },
                          { align: "center" },
                          { align: "right" },
                          { align: "justify" }
                        ],
                        ["blockquote", "code-block"],
                        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                        [{ script: "sub" }, { script: "super" }],
                        [{ indent: "-1" }, { indent: "+1" }],
                        [{ color: [] }, { background: [] }],
                        [{ direction: "rtl" }],
                        ["image"],
                        [{undo: 'undo'}]
                    ],
                    handlers: {
                        undo: () => {
                            var self = this;
                            this.openInsertDialog();
                        },
                    }
                }
            },
        }
    };
  },
  methods: {
    submitForm() {
      this.$refs.addPost.validate(async(valid) => {
        if (!valid) {
          this.$notify({
            title: 'Error',
            message: 'Popraw formularz',
            type: 'error',
            duration: 2000,
          });
          return false;
        } else {
          const formData = new FormData();
          formData.append('title', this.addPost.title);
          formData.append('content', this.addPost.content);
          formData.append('main_photo', this.addPost.image);
          await postResource.store(formData);
          this.addPost = {};
          await this.$router.push({ path: '/blog/posts-list' });
        }
      });
    },
    handlePreview(file) {
      this.previewDialogUrl = file.url;
      this.previewDialogVisible = true;
    },
    handleChangeImages(file) {
      this.addPost.image = file.raw;
    },
    async handleRemove() {
      this.addPost.image = null;
    },
    openInsertDialog() {
        this.insertDialog = true;
    },
    updateValHtml(value) {
      this.htmlContent = value;
      this.addPost.content = this.htmlContent;
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
        const formData = new FormData();
        formData.append('image', file);
        const result = await postResource.imgStore(formData);
        console.log(result)
        Editor.insertEmbed(cursorLocation, 'image', result);
        resetUploader();

      },
  },
  watch: {
      value(value) {
        if (value !== this.local_value) {
          this.addPost.content = this.value;
          this.$emit('set-value', value);
        }
      },
      'addPost.content'(value) {
        this.$emit('set-value', value);
      },
    },
};
</script>

<style lang="scss" scoped>
  .add-post {
    margin: 0 30px;
    .add-post-form {
      //width: 50%;
    }
  }
</style>
